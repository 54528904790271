exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-cart-index-js": () => import("./../../../src/pages/cart/index.js" /* webpackChunkName: "component---src-pages-cart-index-js" */),
  "component---src-pages-catalogue-index-js": () => import("./../../../src/pages/catalogue/index.js" /* webpackChunkName: "component---src-pages-catalogue-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquiries-index-js": () => import("./../../../src/pages/inquiries/index.js" /* webpackChunkName: "component---src-pages-inquiries-index-js" */),
  "component---src-pages-submissions-index-js": () => import("./../../../src/pages/submissions/index.js" /* webpackChunkName: "component---src-pages-submissions-index-js" */),
  "component---src-templates-mood-js": () => import("./../../../src/templates/Mood.js" /* webpackChunkName: "component---src-templates-mood-js" */),
  "component---src-templates-moods-collection-js": () => import("./../../../src/templates/MoodsCollection.js" /* webpackChunkName: "component---src-templates-moods-collection-js" */),
  "component---src-templates-open-call-js": () => import("./../../../src/templates/OpenCall.js" /* webpackChunkName: "component---src-templates-open-call-js" */),
  "component---src-templates-publication-js": () => import("./../../../src/templates/Publication.js" /* webpackChunkName: "component---src-templates-publication-js" */)
}

